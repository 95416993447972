body {
  background: lightgray;
}

.selected-player-detail {
  margin: 1em 2em;
  display: flex;

  .basic-player-info {
    flex-basis: 0;
    flex-grow: 1;
  }
  .player-notes {
    flex-basis: 0;
    flex-grow: 2.5;
    .card-container {
      .card {
        box-shadow: unset;
        margin-bottom: 0;
        height: 190px;
        .vertical-tabs {
          width: 45%;
          justify-content: unset;
          .vertical-tab-name {
            padding: 0.5em;
          }
        }
        .vertical-active-tab-body {
          width: 65%;
          overflow: scroll;
        }
      }
    }
  }
}

.draft-info {
  background: white;
  height: 250px;
  display: flex;
  .player-list {
    width: 300px;
    height: 250px;
    overflow: scroll;
  }
}

.details {
  display: flex;
  .player-details {
    flex-basis: 0;
    flex-grow: 1;
  }
}

.player-status {
  display: flex;
  align-items: center;
  justify-content: right;
  .player-activity {
    flex-basis: 0%;
    flex-grow: 1;
  }
}

.player-ownership {
  display: flex;
  flex-flow: column;

  .player-ownership-stat {
    display: flex;
    margin: 0.25em 0;

    h2 {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}
